const theme = {
  sizes: {
    wBasis: 1440,
    wMax: 850,
    wMaxBig: 1200,
    maxContentWidth: 630,
    maxContentWidthBig: 800,
    safeMargin: 75,
    safeMarginMobile: 30,
    buttonHeight: 65,
  },
  media: {
    veryLargeDesktop: '1920px',
    largeDesktop: '1280px',
    desktop: '1140px',
    tablet: '1024px',
    belowTablet: '1023px',
    tabletPortrait: '768px',
    mobile: '600px'
  },
  colors: {
    white: '#ffffff',
    black: '#000000',
    blue: '#2946D4',
    focusBlue: '#2139C5',
    darkBlue: '#061365',
    red: '#d9534f',
    orange: '#F8A500'
  },
  easing: {
    duration: 0.25,
    default: 'ease-out'
  },
  header: {
    trianglePositions: {
      desktop: {
        left: {
          start: '-10%',
          end: '-70%'
        },
        right: {
          start: '10%',
          end: '70%'
        },
        top: {
          start: '-100%',
          end: '-87%'
        },
        bottom: {
          start: '100%',
          end: '87%'
        }
      },
      tablet: {
        left: {
          start: '-30%',
          end: '-85%'
        },
        right: {
          start: '30%',
          end: '85%'
        },
        top: {
          start: '-100%',
          end: '-85%'
        },
        bottom: {
          start: '100%',
          end: '85%'
        }
      },
      tabletPortrait: {
        left: {
          start: '-65%',
          end: '-85%'
        },
        right: {
          start: '65%',
          end: '85%'
        },
        top: {
          start: '-100%',
          end: '-85%'
        },
        bottom: {
          start: '100%',
          end: '85%'
        }
      },
      mobile: {
        left: {
          start: '-80%',
          end: '-90%'
        },
        right: {
          start: '80%',
          end: '90%'
        },
        top: {
          start: '-100%',
          end: '-85%'
        },
        bottom: {
          start: '100%',
          end: '85%'
        }
      },
      top: {
        start: {
          desktop: '-100%',
          tablet: '-100%',
          tabletPortrait: '-100%',
          mobile: '-100%',
        },
        end: {
          desktop: '-85%',
          tablet: '-85%',
          tabletPortrait: '-85%',
          mobile: '-85%',
        }
      },
      bottom: {
        start: {
          desktop: '10%',
          tablet: '100%',
          tabletPortrait: '100%',
          mobile: '100%',
        },
        end: {
          desktop: '85%',
          tablet: '85%',
          tabletPortrait: '85%',
          mobile: '85%',
        }
      }
    }
  },
  fonts: {
    families: {
      safe: 'Helvetica, Arial, sans-serif',
      neueHaasGrotesk: '"NeueHaasGrotesk", Helvetica, Arial, sans-serif'
    },
    sizes: {
      base: 10, // in px: 1rem => 10px
      default: '1.6rem'
    },
    formats: {
      h1: {
        sizes: {
          veryLargeDesktop: '12rem',
          desktop: '9rem',
          tablet: '8rem',
          mobile: '5rem'
        },
        fontWeight: 200,
        lineHeight: 1.1,
        letterSpacing: '2px',
      },
      headline: {
        sizes: {
          veryLargeDesktop: '3.4rem',
          desktop: '2.8rem',
          tablet: '2.5rem',
          mobile: '1.8rem'
        },
        letterSpacing: '-0.9px',
        lineHeight: 1.5,
        fontWeight: 300
      },
      h2: {
        sizes: {
          desktop: '6.1rem',
          tablet: '6.1rem',
          mobile: '3.8rem'
        },
        lineHeight: 1.25,
        fontWeight: 200,
        letterSpacing: '-2px',
      },
      h3: {
        sizes: {
          desktop: '3.5rem',
          tablet: '3.5rem',
          mobile: '2.5rem'
        },
        lineHeight: 1.15,
        letterSpacing: '-2px',
        fontWeight: 200
      },
      input: {
        sizes: {
          desktop: '2.1rem',
          tablet: '2.1rem',
          mobile: '1.8rem'
        },
        lineHeight: 1.15,
        fontWeight: 500
      },
      paragraph: {
        sizes: {
          desktop: '1.8rem',
          tablet: '1.8rem',
          mobile: '1.6rem'
        },
        letterSpacing: '-0.58px',
        lineHeight: 1.44,
        fontWeight: 300
      },
      cta: {
        sizes: {
          desktop: '1.8rem',
          tablet: '1.8rem',
          mobile: '1.8rem'
        },
        fontWeight: 500
      },
      footer: {
        sizes: {
          desktop: '1.3rem',
          tablet: '1.3rem',
          mobile: '1.3rem'
        },
        lineHeight: 1
      }
    }
  }
}

export default theme
