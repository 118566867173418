import { createSelector } from 'reselect'
import theme from '~styles/theme'

// State
const initialState = {
  showThanks: false,
  scrollController: null,
  scrollDirection: '',
  showHeader: true,
  headerAppearing: true,
  viewport: {
    width: null,
    height: null,
  }
}

// Actions
const SHOW_THANKS = 'SHOW_THANKS'
const SET_SCROLL_CONTROLLER = 'SET_SCROLL_CONTROLLER'
const SET_VIEWPORT = 'SET_VIEWPORT'
const SET_SHOW_HEADER = 'SET_SHOW_HEADER'
const SET_HEADER_APPEARING = 'SET_HEADER_APPEARING'
const SET_SCROLL_DIRECTION = 'SET_SCROLL_DIRECTION'

export const showThanks = () => ({
  type: SHOW_THANKS
})

export const setScrollController = (scrollController) => ({
  type: SET_SCROLL_CONTROLLER, scrollController
})

export const setViewport = (viewport) => ({
  type: SET_VIEWPORT, viewport
})

export const setShowHeader = (showHeader) => ({
  type: SET_SHOW_HEADER, showHeader
})

export const setHeaderAppearing = (headerAppearing) => ({
  type: SET_HEADER_APPEARING, headerAppearing
})

export const setScrollDirection = (scrollDirection) => ({
  type: SET_SCROLL_DIRECTION, scrollDirection
})

// Reducers
export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case SHOW_THANKS:
      return { ...state, showThanks: true }

    case SET_SCROLL_CONTROLLER:
      return { ...state, scrollController: payload.scrollController }

    case SET_VIEWPORT:
      return { ...state, viewport: payload.viewport }

    case SET_SHOW_HEADER:
      return { ...state, showHeader: payload.showHeader }

    case SET_HEADER_APPEARING:
      return { ...state, headerAppearing: payload.headerAppearing }

    case SET_SCROLL_DIRECTION:
      return { ...state, scrollDirection: payload.scrollDirection }

    default:
      return state
  }
}

// selectors
export const isTabletSelector = createSelector(
  state => state.app.viewport.width,
  width => width <= parseInt(theme.media.tablet)
)

export const isTabletPortraitSelector = createSelector(
  state => state.app.viewport.width,
  width => width <= parseInt(theme.media.tabletPortrait)
)

export const isMobileSelector = createSelector(
  state => state.app.viewport.width,
  width => width <= parseInt(theme.media.mobile)
)

export const trianglePosSelector = createSelector(
  state => state.app.viewport.width,
  width => {
    if (width === null) {
      return false
    }

    if (width <= parseInt(theme.media.mobile)) {
      return theme.header.trianglePositions.mobile
    }

    if (width <= parseInt(theme.media.tabletPortrait)) {
      return theme.header.trianglePositions.tabletPortrait
    }

    if (width <= parseInt(theme.media.tablet)) {
      return theme.header.trianglePositions.tablet
    }

    return theme.header.trianglePositions.desktop
  }
)