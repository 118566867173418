import { forEach, mapValues } from 'lodash'

// State
const initialState = {
  // common fields
  name: '',
  email: '',
  phone: '',
  companyName: '',
  goods: '',

  // info fields
  volume: '',
  when: '',
  shippings: [],

  // quote fields
  services: [],
  purpose: '',

  // validation : useful to get isValid for form
  validation: {
    // common
    name: false,
    email: false,
    phone: true,
    companyName: false,
    goods: true,

    // info
    volume: true,
    when: true,
    shippings: true,

    // quote
    services: true,
    purpose: true,
  },

  // dirty : useful to change state on submit and display error even if field untouched
  dirty: {
    // common
    name: false,
    email: false,
    phone: false,
    companyName: false,
    goods: false,

    // info
    volume: false,
    when: false,
    shippings: false,

    // quote
    services: false,
    purpose: false,
  },

  commonFields: ['name', 'email', 'phone', 'companyName', 'goods'],
  infoFields: ['volume', 'when', 'shippings'],
  quoteFields: ['services', 'purpose']
}

// Actions
const SET_FIELD_VALIDATION = 'SET_FIELD_VALIDATION'
const SET_FIELD_VALUE = 'SET_FIELD_VALUE'
const SET_FIELD_DIRTY = 'SET_FIELD_DIRTY'
const SET_ALL_FIELDS_DIRTY = 'SET_ALL_FIELDS_DIRTY'

export const setFieldValidation = (payload) => ({
  type: SET_FIELD_VALIDATION, payload
})

export const setFieldValue = (payload) => ({
  type: SET_FIELD_VALUE, payload
})

export const setFieldDirty = (payload) => ({
  type: SET_FIELD_DIRTY, payload
})

export const setAllFieldsDirty = () => ({
  type: SET_ALL_FIELDS_DIRTY
})

// Reducers
export default (state = initialState, {type, payload}) => {
  switch (type) {
    case SET_FIELD_VALUE:
      return { ...state, [payload.field]: payload.value }

    case SET_FIELD_VALIDATION:
      return { ...state, validation: { ...state.validation, [payload.field]: payload.status } }

    case SET_FIELD_DIRTY:
      return { ...state, dirty: { ...state.dirty, [payload.field]: payload.status } }

    case SET_ALL_FIELDS_DIRTY:
      return { ...state, dirty: mapValues(state.dirty, () => true) }

    default:
      return state
  }
}

// Selectors
export const isInfoFormValidSelector = (state) => {
  let isValid = true

  forEach(state.commonFields, field => {
    if (!state.validation[field]) {
      isValid = false;
      return false
    }
  })

  if (isValid) {
    forEach(state.infoFields, field => {
      if (!state.validation[field]) {
        isValid = false;
        return false
      }
    })
  }

  return isValid
}

export const isQuoteFormValidSelector = (state) => {
  let isValid = true

  forEach(state.commonFields, field => {
    if (!state.validation[field]) {
      isValid = false;
      return false
    }
  })

  if (isValid) {
    forEach(state.quoteFields, field => {
      if (!state.validation[field]) {
        isValid = false;
        return false
      }
    })
  }

  return isValid
}